import { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Popover, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,

} from '@heroicons/react/24/outline'
import Bar from '../Bar'
import { useContext } from 'react'
import { AuthContext } from '../../../context'


const Header = () => {

  const { states, setters, loadConfig } = useContext(AuthContext)

  const [data, setData] = useState([{}])

  useEffect(() => {
    const getData = async () => {
      const config = JSON.parse(localStorage.getItem('config')) || ''
      if (config != '') {
        setData(config)
      } else {
        ///Caso esteja vazia
        await loadConfig()
      }
    }
    getData()
  }, [])


  const [estilo1, setEstilo1] = useState({
    paddingTop: '3px',
    marginRight: '5%', // Este valor será aplicado apenas em telas grandes
    borderBottomWidth: '0px',
  });
  const [estilo2, setEstilo2] = useState({
    paddingTop: '3px',
    marginRight: '5%', // Este valor será aplicado apenas em telas grandes
    borderBottomWidth: '0px',
  });
  const [estilo3, setEstilo3] = useState({
    paddingTop: '3px',
    marginRight: '5%', // Este valor será aplicado apenas em telas grandes
    borderBottomWidth: '0px',
  });
  const [estilo4, setEstilo4] = useState({
    paddingTop: '3px',
    marginRight: '5%', // Este valor será aplicado apenas em telas grandes
    borderBottomWidth: '0px',
  });

  // Atualiza os estilos no hover
  const onHover1 = (setEstilo) => {
    setEstilo1({
      ...estilo1,
      borderBottomWidth: '5px',
      borderBottomColor: data.corPrimaria,
    });
  };

  // Retorna ao estilo original quando o mouse sai
  const onLeave1 = () => {
    setEstilo1({
      ...estilo1,
      borderBottomWidth: '0px'
    });
  };

  // Atualiza os estilos no hover
  const onHover2 = (setEstilo) => {
    setEstilo2({
      ...estilo2,
      borderBottomWidth: '5px',
      borderBottomColor: data.corPrimaria,
    });
  };

  // Retorna ao estilo original quando o mouse sai
  const onLeave2 = () => {
    setEstilo2({
      ...estilo2,
      borderBottomWidth: '0px'
    });
  };

  // Atualiza os estilos no hover
  const onHover3 = (setEstilo) => {
    setEstilo3({
      ...estilo3,
      borderBottomWidth: '5px',
      borderBottomColor: data.corPrimaria,
    });
  };

  // Retorna ao estilo original quando o mouse sai
  const onLeave3 = () => {
    setEstilo3({
      ...estilo3,
      borderBottomWidth: '0px'
    });
  };

  // Atualiza os estilos no hover
  const onHover4 = (setEstilo) => {
    setEstilo4({
      ...estilo4,
      borderBottomWidth: '5px',
      borderBottomColor: data.corPrimaria,
    });
  };

  // Retorna ao estilo original quando o mouse sai
  const onLeave4 = () => {
    setEstilo4({
      ...estilo4,
      borderBottomWidth: '0px'
    });
  };

  return (
    <>
      <Bar />
      <Popover className=" relative w-full bg-white">
        <div className="flex pb-7 p-8  items-center justify-between border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start  flex-1 ">
            <i href="#" className='w-[180px]'>
              <span className="sr-only">Your Company</span>
              <img
                className="w-32 h-32"
                src={`${data.logo}`}
                alt=""
                crossOrigin='anonymous'

              />
            </i>
          </div>
          <div className="-my-2 -mr-2 ls:hidden">
            <Popover.Button
              style={{ backgroundColor: `${data.corPrimaria}` }}
              className="inline-flex items-center justify-center rounded-md bg-[#0F4799] p-2 text-white hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden  space-x-10 ls:flex w-[100%] justify-center ">

            <div className='flex w-[65%] items-center justify-between mr-16'>

              <Link to="/"
                style={estilo1}
                onMouseEnter={onHover1}
                onMouseLeave={onLeave1}

                className={`text-base font-medium text-gray-500 `}>
                PÁGINA INICIAL
              </Link>


              <Link to="/sobre" style={estilo2}
                onMouseEnter={onHover2}
                onMouseLeave={onLeave2}

                className={`text-base font-medium text-gray-500 `}>
                SOBRE NÓS
              </Link>

              <Link to={'/faq'} style={estilo3}
                onMouseEnter={onHover3}
                onMouseLeave={onLeave3}

                className={`text-base font-medium text-gray-500 `}>
                FAQ
              </Link>
              <Link to={'/blog'} style={estilo4}
                onMouseEnter={onHover4}
                onMouseLeave={onLeave4}

                className={`text-base font-medium text-gray-500 `}>
                ÚLTIMAS POSTAGENS
              </Link>
            </div>
          </Popover.Group>


          <div className="hidden  pr-[32] items-center justify-end ls:flex mt-2  lg:w-0">
            <Link
              to="/contato"
              style={{ backgroundColor: `${data.corPrimaria}` }}
              className={`ml-[2em] inline-flex text-center  h-[50px] text-[21px] items-center justify-center whitespace-nowrap rounded-full border border-transparent  px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700`}
            >
              <span className='w-[110px]'>
                Contato
              </span>
            </Link>

          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel focus className="absolute inset-x-0 z-[100] top-0 origin-top-right transform p-2 transition ls:hidden">
            <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="px-5 pt-5 pb-6">
                <div className="flex items-center justify-between">
                  <div>
                    <img
                      className="h-8 w-auto"
                      src={`${data.logo}`}
                      alt="Your Company"
                      crossOrigin='anonymous'
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only ">Close menu</span>
                      <XMarkIcon className="h-6 w-6 " aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    <Link to="/"
                      style={estilo1}
                      onMouseEnter={onHover1}
                      onMouseLeave={onLeave1}

                      className={`text-base font-medium text-gray-500 `}>
                      PÁGINA INICIAL
                    </Link>


                    <Link to="/sobre" style={estilo2}
                      onMouseEnter={onHover2}
                      onMouseLeave={onLeave2}

                      className={`text-base font-medium text-gray-500 `}>
                      SOBRE NÓS
                    </Link>

                    <Link to={'/faq'} style={estilo3}
                      onMouseEnter={onHover3}
                      onMouseLeave={onLeave3}

                      className={`text-base font-medium text-gray-500 `}>
                      FAQ
                    </Link>
                    <Link to={'/blog'} style={estilo4}
                      onMouseEnter={onHover4}
                      onMouseLeave={onLeave4}

                      className={`text-base font-medium text-gray-500 `}>
                      ÚLTIMAS POSTAGENS
                    </Link>

                  </nav>
                </div>
              </div>
              <div className="space-y-6 py-6 px-5">
                <div>
                  <Link
                    style={{ backgroundColor: `${data.corPrimaria}` }}
                    className="flex w-full items-center justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700"
                    to={'/contato'}
                  >
                    Fale conosco!
                  </Link>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  )
}

export default Header;