import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

const ModalMail = ({ states, setters, data, send }) => {
    const [open, setOpen] = useState(true)

    const cancelButtonRef = useRef(null)

    // const formatTelefone = (telefone) => {
    //     //Com ddd 
    //     const ddd = telefone?.substring(0, 2)
    //     const parte1 = telefone?.substring(2, 7)
    //     const parte2 = telefone?.substring(7, 11)
    //     return `(${ddd}) ${parte1}-${parte2}`

    // }
    const [titulo, setTitulo] = useState('')
    const [descricao, setDescricao] = useState('')
    const [email, setEmail] = useState(data?.email)
    const [nome, setNome] = useState(data?.nome)

    useEffect(() => {
        setNome(data?.nome)
        setEmail(data?.email)
    }, [])

    const senMail = () => {
        const obj = {
            title: titulo,
            descricao,
            email: data.email,
            nome: data.nome
        }
        setters.setModalSendMail(false)
        send(obj)
    }

    useEffect(() => {
        if (!states.modalSendMail) {
            setTitulo('')
            setDescricao('')
            setEmail('')
            setNome('')
        }
    }, [states.modalSendMail])

    return (
        <Transition.Root show={states.modalSendMail} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setters.setModalSendMail}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto mb-8">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <InformationCircleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-[100%]">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Enviar e-mail para: {data.nome}
                                        </Dialog.Title>
                                        <div className="mt-2 border rounded-[10px] p-2">
                                            <p className="text-sm text-gray-500">
                                                E-mail: {data.email}
                                            </p>
                                        </div>
                                        <div className="mt-2 border rounded-[10px] p-2 ">
                                            <p className="text-sm text-gray-500"> Título: </p>
                                            <input className="w-full h-10 border rounded-[10px] p-2 mt-2"
                                                value={titulo} onChange={(e) => setTitulo(e.target.value)}
                                            />
                                        </div>
                                        <div className="mt-2 border rounded-[10px] p-2 ">
                                            <p className="text-sm text-gray-500"> Menssagem: </p>
                                            <textarea className="w-full h-32 border rounded-[10px] p-2 mt-2"
                                                value={descricao} onChange={(e) => setDescricao(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md border hover:bg-gray-200  bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm  sm:ml-3 sm:w-auto"
                                        onClick={() => setters.setModalSendMail(false)}
                                    >
                                        Fechar
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                                        onClick={senMail}
                                    >
                                        Enviar
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}


export default ModalMail;