import Footer from "../../../components/Footer/Foooter";
import Header from "../../../components/Header/Header";
import { Link } from "react-router-dom";
import { useEffect, useContext, useState } from "react";
import { AuthContext } from '../../../context'
import Loaging from '../../../components/Load'
import ErrorSite from '../../../components/errorSite'

export default function About() {

    const { states, setters, loadDataSobre } = useContext(AuthContext)


    const [data, setData] = useState({})
    const [rgba1, setRgba1] = useState('')
    const [rgba2, setRgba2] = useState('')
    const [rgba3, setRgba3] = useState('')
    const hexToRGBA = (hex, alpha) => {
        var r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };
    useEffect(() => {
        const config = JSON.parse(localStorage.getItem('config'))
        setData(config)
        setRgba1(hexToRGBA(config.corPrimaria, 0.5))
        setRgba2(hexToRGBA(config.corSecundaria, 0.5))
        setRgba3(hexToRGBA(config.corTerciaria, 0.5))

    }, [])

    const load = async () => {
        await loadDataSobre()
    }

    useEffect(() => {
        load()
    }, [])

    if (states.loading) {
        return <Loaging />
    } else {
        if (Object.keys(states.dataSobre).length > 0) {
            return (
                <div className="">
                    <ErrorSite />
                    <Header />
                    <div className="flex flex-col justify-center items-center p-[67px] " style={{
                        height: '200px', // Ajuste a altura conforme necessário
                        background: `
                               repeating-linear-gradient(
                                   40deg,
                                   ${rgba1},
                                   ${rgba2} 45px,
                                   transparent 10px,
                                   transparent 20px
                               )
                           `,
                        backgroundSize: '40px 40px',
                    }}>
                        <h1 className="flex flex-col sm:flex-row  items-center junstify-center font-['Be Vietnam Bold'] font-bold text-white text-[50px] sm:text-[50px] w-full sm:w-[27%]">Quem <span className="ml-4">Somos</span></h1>
                        <div className="sm:flex flex-row hidden">
                            <Link to="/" className="text-gray-200">Pagina inicial</Link>
                            <span className="text-white ml-2 "> &rsaquo; Quem somos </span>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row pt-10 pb-10">
                        <div className="flex flex-row md:w-[40%] items-center justify-center">
                            <img
                                className="w-[88%] sm:mb-[58%] mb-[0%] "
                                src={states.dataSobre.foto}
                                crossOrigin="anonymous"
                            />
                        </div>
                        <div className="flex flex-row justify-start items-start md:w-[50%] p-7">
                            <div className="flex flex-col mt-6 ">
                                <p className="text-justify font-['Be Vietnam Bold'] whitespace-pre-line pb-6 text-[18px] font-serif italic">
                                    {states.dataSobre.descricao}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col items-center justify-center p-[60px] pb-[100px]"
                        style={{
                            height: '400px', // Ajuste a altura conforme necessário
                            background: `
                               repeating-linear-gradient(
                                   50deg,
                                   ${rgba1},
                                   ${rgba3} 35px,
                                   transparent 10px,
                                   transparent 20px
                               )
                           `,
                            backgroundSize: '40px 40px',
                        }}
                    >
                        <h1 className="text-[#001850] sm:text-[50px] text-[33px] text-center font-['Be Vietnam Bold'] font-bold">Entre em contato conosco</h1>
                        <Link to="/contato" type="button" className="flex flex-col justify-center mt-6 inline-block w-[200px] h-[50px] px-6 py-2.5 bg-green-700 text-white text-center font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"><span className="text-[12px]">Entrar em contato</span></Link>
                    </div>
                    <Footer />
                </div>
            )
        }
    }





}