


import { XMarkIcon } from '@heroicons/react/24/solid'
import { useState, useEffect } from 'react';
import Arquivo from '../Arquivos'


const Config = ({ data, semArquivo, comArquivo, post }) => {

    const [errors, setErrors] = useState({
        corPrimaria: false,
        corSecundaria: false,
        corTerciaria: false,
        email: false,
        whatsapp: false,
        facebook: false,
        instagram: false

    })
    const [objConfig, setObjConfig] = useState({
        corPrimaria: '',
        corSecundaria: '',
        corTerciaria: '',
        email: '',
        whatsapp: '',
        facebook: '',
        instagram: '',
    })

    const [files, setFiles] = useState([])
    const [isValid, setIsValid] = useState(true);
    const [isEdit, setIsEdit] = useState(false);

    const isValidHex = (hex) => /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hex);


    useEffect(() => {
        if (Object.keys(data).length > 0) {
            setObjConfig(item => ({
                ...item,
                id: data.id,
                corPrimaria: data.corPrimaria,
                corSecundaria: data.corSecundaria,
                corTerciaria: data.corTerciaria,
                email: data.email,
                whatsapp: data.whatsapp,
                facebook: data.facebook,
                instagram: data.instagram

            }))
            setIsEdit(true)
        }
    }, [data])

    const formatarTelefone = (numero) => {
        // Remove caracteres não numéricos
        const apenasNumeros = numero.replace(/\D/g, '');

        // Verifica se o número começa com o prefixo 55 e tem o tamanho esperado
        if (apenasNumeros.startsWith('55') && (apenasNumeros.length === 13 || apenasNumeros.length === 14)) {
            const prefixoPais = apenasNumeros.substring(0, 2);
            const codigoArea = apenasNumeros.substring(2, 4);
            const inicioNumero = apenasNumeros.substring(4, apenasNumeros.length - 4);
            const finalNumero = apenasNumeros.substring(apenasNumeros.length - 4);

            return `+${prefixoPais} (${codigoArea}) ${inicioNumero}-${finalNumero}`;
        } else {
            return numero;
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if (isEdit) {
            if (files.length == 0) {
                semArquivo(objConfig)
            } else {
                const formData = new FormData();
                formData.append('logo', files[0]);
                formData.append('corPrimaria', objConfig.corPrimaria);
                formData.append('corSecundaria', objConfig.corSecundaria);
                formData.append('corTerciaria', objConfig.corTerciaria);
                formData.append('email', objConfig.email);
                formData.append('whatsapp', objConfig.whatsapp);
                formData.append('facebook', objConfig.facebook);
                formData.append('instagram', objConfig.instagram);
                comArquivo(formData, objConfig.id)
            }
        } else {
            const formData = new FormData();
            formData.append('logo', files[0]);
            formData.append('corPrimaria', objConfig.corPrimaria);
            formData.append('corSecundaria', objConfig.corSecundaria);
            formData.append('corTerciaria', objConfig.corTerciaria);
            formData.append('email', objConfig.email);
            formData.append('whatsapp', objConfig.whatsapp);
            formData.append('facebook', objConfig.facebook);
            formData.append('instagram', objConfig.instagram);
            post(formData)
            //Aqui ele deve realizar o cadastro normal -> TESTE
        }


    }

    console.log(data)

    return (
        <form className='flex w-full flex-col' onSubmit={onSubmit}>
            <div className="space-y-12">
                <div className="border-b border-gray-900/10 pb-12">
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        Configurações base do seu site
                    </p>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="col-span-1">

                            <div className="mt-2 flex items-center gap-x-3">
                                <div>
                                    <img className="h-24 w-24 rounded-full" src={files.length > 0 ? URL.createObjectURL(files[0]) : Object.keys(data).length > 0 ? data.logo : 'https://img.freepik.com/free-vector/no-data-concept-illustration_114360-2506.jpg?w=1480&t=st=1699883644~exp=1699884244~hmac=6b12aaeecf39819ebe0eb7b6d3e796090fb2bfe933c81e728299f54629e546f6'
                                    } alt=""
                                        crossOrigin='anonymous'
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-span-5">
                            <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                Seleção da logo
                            </label>
                            {
                                Object.keys(files).length > 0 && (
                                    <div className="mt-2 flex items-center gap-x-3">
                                        <div className="flex flex-col gap-y-2">
                                            <div className="flex items-center gap-x-2">
                                                <span className="text-sm font-medium leading-6 text-gray-900">
                                                    {files[0].name}
                                                </span>
                                                <XMarkIcon onClick={() => setFiles([])} className="h-5 w-5 text-red-500 cursor-pointer" aria-hidden="true" />
                                            </div>
                                            <span className="text-sm leading-6 text-gray-500">
                                                {files[0].size} bytes
                                            </span>
                                        </div>
                                    </div>
                                )
                            }

                            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                <Arquivo files={files} setFiles={setFiles} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Demais informações</h2>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-2">
                            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                Primeira cor
                            </label>
                            <div className="mt-2 flex gap-2 items-center">
                                <input
                                    type="color"
                                    value={objConfig.corPrimaria}
                                    name="first-name"
                                    id="first-name"
                                    onChange={(e) => setObjConfig({ ...objConfig, corPrimaria: e.target.value })}
                                    autoComplete="given-name"
                                    className="w-10 h-10  border-0 shadow-sm focus:ring-2 focus:ring-indigo-600"
                                />
                                <input
                                    value={objConfig.corPrimaria}
                                    onChange={e => setObjConfig({ ...objConfig, corPrimaria: e.target.value })}
                                    type="text"
                                    name="hex-value"
                                    id="hex-value"
                                    placeholder="#ffffff"
                                    className={`block w-full rounded-md border py-1.5 text-gray-900 shadow-sm focus:ring-2 sm:text-sm sm:leading-6 ${isValid ? 'border-gray-300 focus:ring-indigo-600' : 'border-red-500 focus:ring-red-500'}`}

                                />

                            </div>
                            {!isValid && <p className="text-red-500 text-sm mt-1">Insira um valor hexadecimal válido.</p>}
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                Segunda cor
                            </label>
                            <div className="mt-2 flex gap-2 items-cente">
                                <input
                                    type="color"
                                    value={objConfig.corSecundaria}
                                    name="first-name"
                                    id="first-name"
                                    onChange={(e) => setObjConfig({ ...objConfig, corSecundaria: e.target.value })}
                                    autoComplete="given-name"
                                    className="w-10 h-10  border-0 shadow-sm focus:ring-2 focus:ring-indigo-600"
                                />
                                <input
                                    value={objConfig.corSecundaria}
                                    onChange={e => setObjConfig({ ...objConfig, corSecundaria: e.target.value })}
                                    type="text"
                                    name="hex-value"
                                    id="hex-value"
                                    placeholder="#ffffff"
                                    className={`block w-full rounded-md border py-1.5 text-gray-900 shadow-sm focus:ring-2 sm:text-sm sm:leading-6 ${isValid ? 'border-gray-300 focus:ring-indigo-600' : 'border-red-500 focus:ring-red-500'}`}

                                />
                            </div>
                            {!isValid && <p className="text-red-500 text-sm mt-1">Insira um valor hexadecimal válido.</p>}
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Terceira cor
                            </label>
                            <div className="mt-2 flex gap-2 items-cente">
                                <input
                                    type="color"
                                    value={objConfig.corTerciaria}
                                    name="first-name"
                                    id="first-name"
                                    onChange={(e) => setObjConfig({ ...objConfig, corTerciaria: e.target.value })}
                                    autoComplete="given-name"
                                    className="w-10 h-10  border-0 shadow-sm focus:ring-2 focus:ring-indigo-600"
                                />
                                <input
                                    value={objConfig.corTerciaria}
                                    onChange={e => setObjConfig({ ...objConfig, corTerciaria: e.target.value })}
                                    type="text"
                                    name="hex-value"
                                    id="hex-value"
                                    placeholder="#ffffff"
                                    className={`block w-full rounded-md border py-1.5 text-gray-900 shadow-sm focus:ring-2 sm:text-sm sm:leading-6 ${isValid ? 'border-gray-300 focus:ring-indigo-600' : 'border-red-500 focus:ring-red-500'}`}

                                />

                                {!isValid && <p className="text-red-500 text-sm mt-1">Insira um valor hexadecimal válido.</p>}
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
                                E-mail
                            </label>
                            <div className="mt-2">
                                <input
                                    value={objConfig.email}
                                    onChange={e => setObjConfig({ ...objConfig, email: e.target.value })}
                                    type="text"
                                    name="postal-code"
                                    id="postal-code"
                                    autoComplete="postal-code"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
                                Facebook
                            </label>
                            <div className="mt-2">
                                <input
                                    value={objConfig.facebook}
                                    onChange={e => setObjConfig({ ...objConfig, facebook: e.target.value })}
                                    type="text"
                                    name="postal-code"
                                    id="postal-code"
                                    autoComplete="postal-code"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
                                Instagram
                            </label>
                            <div className="mt-2">
                                <input
                                    value={objConfig.instagram}
                                    onChange={e => setObjConfig({ ...objConfig, instagram: e.target.value })}
                                    type="text"
                                    name="postal-code"
                                    id="postal-code"
                                    autoComplete="postal-code"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
                                Whatsapp
                            </label>
                            <div className="mt-2">
                                <input
                                    value={formatarTelefone(objConfig.whatsapp)}
                                    onChange={e => setObjConfig({ ...objConfig, whatsapp: e.target.value })}
                                    type="text"
                                    name="postal-code"
                                    id="postal-code"
                                    autoComplete="postal-code"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                    Cancel
                </button>
                <button
                    type="submit"
                    className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                    {
                        Object.keys(data).length > 0 ? 'Atualizar' : 'Salvar'
                    }
                </button>
            </div>
        </form>
    )


}



export default Config;
