




export default function Maitence() {


    const host = window.location.host;
    const protocol = window.location.protocol;

    return (
        <>
            <main className=" isolate min-h-full">
                <img
                    src="https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75"
                    alt=""
                    className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
                />
                <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
                    <img
                        src={`${protocol}//${host}/logo.png`}
                        alt="Logo"
                        className="mx-auto h-64"
                    />
                    <p className="text-base font-semibold leading-8 text-white">Ola, Seja bem-vindo a SOTER TEC</p>
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">No momento estamos com nosso site em manutenção</h1>
                    <p className="mt-4 text-base text-white/70 sm:mt-6">Para maiores informações, clique no botão para falar conosco pelo WhatsApp ou acesse nosso portifólio.</p>
                    <div className="mt-10 flex flex-col justify-center">
                        <a 
                            href="https://api.whatsapp.com/send?phone=5531991778224&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20os%20servi%C3%A7os%20da%20SOTER%20TEC"
                         className="text-sm font-semibold leading-7 text-white">
                            Chamar no WhatsApp
                        </a>
                        <a href={`${protocol}//${host}/apresentacao.pdf`} target="_blank" className="text-sm font-semibold leading-7 text-white">
                            Acessar portifólio
                        </a>
                    </div>
                </div>
            </main>
        </>
    )
}
