import { useState, createContext } from 'react';
import { CLIENT, URLAPI } from '../certs/config';

import { toast } from 'react-toastify'

import axios from '../axios'

// import axios from 'axios'

export const AuthContext = createContext({})




const AuthProvider = ({ children }) => {
    //Loading apenas quando esta carregando as configuracoes
    const [isLoading, setIsLoading] = useState(false)

    const [loading, setLoading] = useState(false)
    const [modalContact, setModalContact] = useState(false)

    const [dataConfig, setDataConfig] = useState({})
    const [errorConfig, setErrorConfig] = useState(false)
    const [dataLoadHome, setDataLoadHome] = useState({})
    const [dataSobre, setDataSobre] = useState({})
    const [dataFaq, setDataFaq] = useState([])

    const [dataContato, setDataContato] = useState({})
    const [dataNaoEncontrado, setDataNaoEncontrado] = useState({})


    const [dataLogin, setDataLogin] = useState({})

    const [token, setToken] = useState('')
    const [signed, setSigned] = useState(false)
    const [sidebarOpen, setSidebarOpen] = useState(false)

    const [dataSecao1, setDataSecao1] = useState({})
    const [dataSecao2, setDataSecao2] = useState({})
    const [dataSecao3, setDataSecao3] = useState({})
    const [dataSecao4, setDataSecao4] = useState({})
    const [dataSecao5, setDataSecao5] = useState({})
    const [dataSecao6, setDataSecao6] = useState({})

    const [dataEmails, setDataEmails] = useState([])
    const [offsetDataEmails, setOffsetDataEmails] = useState(0)
    const [limitDataEmails, setLimitDataEmails] = useState(10)
    const [limiteBuscaDataEmails, setLimiteBuscaDataEmails] = useState(false)

    const [modalMail, setModalMail] = useState(false)
    const [modalSendMail, setModalSendMail] = useState(false)

    const [dataBlog, setDataBlog] = useState([])
    const [blog, setBlog] = useState({})
    const [offsetDataBlog, setOffsetDataBlog] = useState(0)
    const [limitDataBlog, setLimitDataBlog] = useState(10)
    const [limiteBuscaDataBlog, setLimiteBuscaDataBlog] = useState(false)
    const [modalBlog, setModalBlog] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)

    const [perfil, setPerfil] = useState({})


    const [send, setSend] = useState(false)

    const [siteBlock, setSiteBlock] = useState(false)


    const states = {
        siteBlock,
        perfil,
        isEdit,
        modalDelete,
        blog,
        modalBlog,
        dataBlog,
        offsetDataBlog,
        limitDataBlog,
        limitDataBlog,
        limiteBuscaDataBlog,
        send,
        modalSendMail,
        modalMail,
        dataEmails,
        offsetDataEmails,
        limitDataEmails,
        limiteBuscaDataEmails,
        loading,
        dataLoadHome,
        dataConfig,
        isLoading,
        modalContact,
        dataSobre,
        dataFaq,
        dataContato,
        dataNaoEncontrado,
        dataLogin,
        token,
        signed,
        sidebarOpen,
        errorConfig,
        dataSecao1,
        dataSecao2,
        dataSecao3,
        dataSecao4,
        dataSecao5,
        dataSecao6

    }

    const setters = {
        setSiteBlock,
        setPerfil,
        setModalDelete,
        setBlog,
        setIsEdit,
        setModalBlog,
        setDataBlog,
        setOffsetDataBlog,
        setLimitDataBlog,
        setLimiteBuscaDataBlog,
        setSend,
        setModalSendMail,
        setModalMail,
        setDataEmails,
        setOffsetDataEmails,
        setLimitDataEmails,
        setLimiteBuscaDataEmails,
        setLoading,
        setDataLoadHome,
        setDataConfig,
        setIsLoading,
        setModalContact,
        setDataSobre,
        setDataFaq,
        setDataContato,
        setDataNaoEncontrado,
        setDataLogin,
        setToken,
        setSigned,
        setSidebarOpen,
        setErrorConfig,
        setDataSecao1,
        setDataSecao2,
        setDataSecao3,
        setDataSecao4,
        setDataSecao5,
        setDataSecao6

    }


    const loadConfig = async () => {
        try {
            setIsLoading(true)
            const result = await axios.get(`${URLAPI}/configuracoes/webSite`)
            localStorage.setItem('config', JSON.stringify(result.data.data))
            setDataConfig(result.data.data)
            setIsLoading(false)
        } catch (e) {
            console.log(e)
            console.log(e)
            setErrorConfig(true)
            setIsLoading(false)
            setDataConfig({})
            toast.error('Erro ao carregar configurações')
        }
    }


    const updateConfigSemArquivo = async (data) => {
        const req = {
            headers: {
                'authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }
        try {
            await axios.put(`/configuracoes/campos/${data.id}`, data, req)
            toast.success('Configurações atualizadas')
            loadConfig();
        } catch (err) {
            toast.error(err.response.data.msg)
        }
    }

    const updateConfigComArquivo = async (data, id) => {
        const req = {
            headers: {
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'multipart/form-data'
            }
        }
        try {
            await axios.put(`/configuracoes/arquivo/${id}`, data, req)
            toast.success('Configurações atualizadas')
            setDataConfig({});
            loadConfig();
        } catch (err) {

            console.log(err)
            toast.error(err.response.data.msg)
        }
    }

    const postConfig = async (data) => {
        const req = {
            headers: {
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'multipart/form-data'
            }
        }
        try {
            await axios.post(`/configuracoes/user`, data, req)
            toast.success('Configurações criadas com sucesso')
            setDataConfig({});
            loadConfig();
        } catch (err) {

            console.log(err)
            toast.error(err.response.data.msg)
        }
    }


    const loadDataHome = async () => {
        try {
            setLoading(true)
            const result = await axios.get('/homePage/admin')
            setLoading(false)
            setDataLoadHome(result.data.data)
        } catch (e) {
            setDataLoadHome({})
            setLoading(false)
            console.log(e)
        }
    }

    const loadDataHomeSecao1 = async () => {
        try {
            setLoading(true)
            const req = {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }
            const result = await axios.get('/homePage/secao1', req)
            setLoading(false)
            setDataSecao1(result.data.data)
        } catch (e) {
            setDataSecao1({})
            setLoading(false)
            console.log(e)
        }
    }

    const updateSecao1 = async (data, id) => {
        try {
            setLoading(true)
            const req = {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'multipart/form-data'
                }
            }
            await axios.put(`/homePage/secao1/${id}`, data, req)
            setLoading(false)
            setDataSecao1({})
            toast.success('Seção atualizada')
            loadDataHomeSecao1();
        } catch (e) {
            toast.error(e.response.data.msg)
            setLoading(false)
            console.log(e)
        }
    }

    const loadDataHomeSecao2 = async () => {
        try {
            setLoading(true)
            const req = {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }
            const result = await axios.get('/homePage/secao2', req)
            setLoading(false)
            setDataSecao2(result.data.data)
        } catch (e) {
            setDataSecao2({})
            setLoading(false)
            console.log(e)
        }
    }


    const updateSecao2 = async (data, id) => {
        try {
            setLoading(true)
            const req = {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'multipart/form-data'
                }
            }
            await axios.put(`/homePage/secao2/${id}`, data, req)
            setLoading(false)
            setDataSecao2({})
            toast.success('Seção atualizada')
            loadDataHomeSecao2();
        } catch (e) {
            toast.error(e.response.data.msg)
            setLoading(false)
            console.log(e)
        }
    }


    const loadDataHomeSecao3 = async () => {
        try {
            const req = {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }
            setLoading(true)
            const result = await axios.get('/homePage/secao3', req)
            setLoading(false)
            console.log(result.data.data)
            setDataSecao3(result.data.data)
        } catch (e) {
            setDataSecao3({})
            setLoading(false)
            console.log(e)
        }
    }


    const updateSecao3 = async (data, id) => {
        try {
            setLoading(true)
            const req = {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'multipart/form-data'
                }
            }
            await axios.put(`/homePage/secao3/${id}`, data, req)
            setLoading(false)
            setDataSecao3({})
            toast.success('Seção atualizada')
            loadDataHomeSecao3();
        } catch (e) {
            toast.error(e.response.data.msg)
            setLoading(false)
            console.log(e)
        }
    }

    const loadDataHomeSecao4 = async () => {
        try {
            setLoading(true)
            const req = {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }
            const result = await axios.get('/homePage/secao4', req)
            setLoading(false)
            setDataSecao4(result.data.data)
        } catch (e) {
            setDataSecao4({})
            setLoading(false)
            console.log(e)
        }
    }


    const updateSecao4 = async (data, id) => {
        try {
            setLoading(true)
            const req = {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'multipart/form-data'
                }
            }
            await axios.put(`/homePage/secao4/${id}`, data, req)
            setLoading(false)
            setDataSecao4({})
            toast.success('Seção atualizada')
            loadDataHomeSecao4();
        } catch (e) {
            toast.error(e.response.data.msg)
            setLoading(false)
            console.log(e)
        }
    }

    const loadDataHomeSecao5 = async () => {
        try {
            setLoading(true)
            const req = {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }
            const result = await axios.get('/homePage/secao5', req)
            console.log(result.data.data)
            console.log(result.data.data)
            setDataSecao5({})
            setLoading(false)
            setDataSecao5(result.data.data)
        } catch (e) {
            setLoading(false)
            console.log(e)
        }
    }


    const updateSecao5 = async (data, id) => {
        try {
            setLoading(true)
            const req = {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'multipart/form-data'
                }
            }
            await axios.put(`/homePage/secao5/${id}`, data, req)
            setLoading(false)
            setDataSecao5({})
            toast.success('Seção atualizada')
            loadDataHomeSecao5();
        } catch (e) {
            toast.error(e.response.data.msg)
            setLoading(false)
            console.log(e)
        }
    }


    const loadDataHomeSecao6 = async () => {
        try {
            setLoading(true)
            const req = {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }
            const result = await axios.get('/homePage/secao6', req)
            setLoading(false)
            setDataSecao6(result.data.data)
        } catch (e) {
            setDataSecao6({})
            setLoading(false)
            console.log(e)
        }
    }


    const updateSecao6 = async (data, id) => {
        try {
            setLoading(true)
            const req = {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'multipart/form-data'
                }
            }
            await axios.put(`/homePage/secao6/${id}`, data, req)
            setLoading(false)
            setDataSecao6({})
            toast.success('Seção atualizada')
            loadDataHomeSecao6();
        } catch (e) {
            toast.error(e.response.data.msg)
            setLoading(false)
            console.log(e)
        }
    }

    const loadDataSobre = async () => {
        try {
            setLoading(true)
            const result = await axios.get('/sobrePagina')
            setLoading(false)
            setDataSobre(result.data.data)
        } catch (e) {
            setDataSobre({})
            setLoading(false)
            console.log(e)
        }
    }

    const updateDataSobre = async (data, id) => {
        try {
            setLoading(true)
            const req = {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'multipart/form-data'
                }

            }
            await axios.put(`/sobrePagina/${id}`, data, req)
            setLoading(false)
            setDataSobre({})
            loadDataSobre();
            toast.success('Dados atualizados')
        } catch (e) {
            setDataSobre({})
            setLoading(false)
            toast.error(e.response.data.msg)
            console.log(e)
        }
    }

    const loadDataFaq = async () => {
        try {
            setLoading(true)
            const result = await axios.get('/faq')
            setDataFaq(result.data.data)
            setLoading(false)
        } catch (e) {
            setDataFaq([])
            setLoading(false)
            console.log(e)
        }
    }


    const updateDataFaq = async (data) => {
        setLoading(true)
        const req = {
            headers: {
                'authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }
        try {
            await axios.put(`/faq`, data, req)
            toast.success('Dados atualizados')
            setLoading(false)
            loadDataFaq();
        } catch (err) {
            toast.error(err.response.data.msg)
        }
    }



    const loadDataContato = async () => {
        try {
            setLoading(true)
            const result = await axios.get('/contato')
            console.log(result.data.data)
            setLoading(false)
            setDataContato(result.data.data)
        } catch (e) {
            setLoading(false)
            console.log(e)
        }
    }


    const updateDataContato = async (data, id) => {
        try {
            setLoading(true)
            const req = {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'multipart/form-data'
                }

            }
            await axios.put(`/contato/${id}`, data, req)
            setLoading(false)
            setDataContato({})
            loadDataContato();
            toast.success('Dados atualizados')
        } catch (e) {
            setLoading(false)
            toast.error(e.response.data.msg)
            console.log(e)
        }
    }

    const loadDataNotFound = async () => {
        try {
            setLoading(true)
            const result = await axios.get('/paginaNaoEncontrada')
            setDataNaoEncontrado(result.data.data)
            setLoading(false)
        } catch (e) {
            setDataNaoEncontrado({})
            setLoading(false)
            console.log(e)
        }
    }


    const updateDataNotFound = async (data, id) => {
        try {
            setLoading(true)
            const req = {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'multipart/form-data'
                }

            }
            await axios.put(`/paginaNaoEncontrada/${id}`, data, req)
            setLoading(false)
            setDataNaoEncontrado({})
            loadDataNotFound();
            toast.success('Dados atualizados')
        } catch (e) {
            setLoading(false)
            toast.error(e.response.data.msg)
            console.log(e)
        }
    }

    const login = async () => {
        try {
            const response = await axios.post('/user/login', { ...dataLogin })
            setSigned(true)
            localStorage.setItem('token', response.data.data.token)
            localStorage.setItem('email', response.data.data.user)
            return { status: true }
        } catch (err) {
            return { status: false, msg: err.response.data.msg }
        }
    }

    const checkLogin = async () => {
        const token = 'Bearer ' + localStorage.getItem('token')
        const req = {
            headers: {
                authorization: token
            }
        }
        try {
            await axios.get('/user/verify', req)
            return true;
        } catch (e) {
            console.log(e)
            setSigned(false)
            toast.error('Sessão expirada')
            localStorage.removeItem('token')
            localStorage.removeItem('email')
            return false;
        }
    }

    const loadEmailsRecebidos = async () => {
        setLoading(true)
        const req = {
            headers: {
                'authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }
        try {
            const result = await axios.get(`/contato/getWithLimit/${limitDataEmails}&${offsetDataEmails}`, req)
            setDataEmails(result.data.data)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.log(e)
            if (e.response.status === 400) {
                if (offsetDataEmails > 0) setOffsetDataEmails(offsetDataEmails - limiteBuscaDataEmails)
                setLimiteBuscaDataEmails(true)
            } else {
                toast.error('Erro ao carregar emails')
            }

        }
    }

    const searchEmailsRecebidos = async (data) => {
        setLoading(true)
        const req = {
            headers: {
                'authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }
        try {
            const result = await axios.get(`/contato/getByQuery/${data}`, req)
            setDataEmails(result.data.data)
            setLoading(false)
            toast.success('Emails carregados com sucesso')
        } catch (e) {
            console.log(e)
            setLoading(false)
            toast.error(e.response.data.msg)
        }
    }

    const enviarEmail = async (data) => {
        setLoading(true)
        const req = {
            headers: {
                'authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }
        try {
            await axios.post(`/contato/sendMail`, data, req)
            setLoading(false)
            toast.success('Email enviado com sucesso')
        } catch (e) {
            console.log(e)
            setLoading(false)
            toast.error(e.response.data.msg)
        }
    }

    const enviarEmailCliente = async (data) => {
        setLoading(true)
        try {
            await axios.post(`/contato/form`, data)
            setLoading(false)
            setSend(true)
            toast.success('Contato realizado com sucesso!')
            return true;
        } catch (e) {
            console.log(e)
            setLoading(false)
            toast.error(e.response.data.msg)
            return false;
        }
    }

    const getWithLimitBlog = async () => {
        try {
            setLoading(true)
            const result = await axios.get(`/blog/getWithLimit/${limitDataBlog}&${offsetDataBlog}`)
            setLoading(false)
            setDataBlog(result.data.data)
        } catch (e) {
            setLoading(false)
            if (e.response.status === 400) {
                setLimiteBuscaDataBlog(true)
            } else {
                toast.error('Erro ao carregar blog')
            }

        }

    }

    const getBlogById = async (id) => {
        try {
            setLoading(true)
            const result = await axios.get(`/blog/${id}`)
            setLoading(false)
            setBlog(result.data.data)
        } catch (e) {
            setLoading(false)
            toast.error('Erro ao carregar blog')


        }

    }

    const createBlog = async (data) => {
        try {
            setLoading(true)
            const req = {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'multipart/form-data'
                }
            }
            await axios.post(`/blog`, data, req)
            setLoading(false)
            setDataBlog([])
            setOffsetDataBlog(0)
            getWithLimitBlog();
            toast.success('Blog criado com sucesso')
        } catch (e) {
            console.log(e)
            console.log(e)
            setLoading(false)
            toast.error(e.response.data.msg)

        }

    }

    const editlog = async (data, id) => {
        try {
            setLoading(true)
            const req = {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'multipart/form-data'
                }
            }
            await axios.put(`/blog/${id}`, data, req)
            setLoading(false)
            setDataBlog([])
            setOffsetDataBlog(0)
            getWithLimitBlog();
            toast.success('Blog atualizado com sucesso')
        } catch (e) {
            console.log(e)
            console.log(e)
            setLoading(false)
            toast.error(e.response.data.msg)

        }

    }

    const deleteBlog = async (id) => {
        try {
            setLoading(true)
            const req = {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }
            await axios.delete(`/blog/${id}`, req)
            setLoading(false)
            setDataBlog([])
            setOffsetDataBlog(0)
            getWithLimitBlog();
            toast.success('Blog deletado com sucesso')
            setBlog({})
        } catch (e) {
            setBlog({})
            setLoading(false)
            toast.error(e.response.data.msg)

        }

    }





    // const esqueceuSenha = async () => {
    //     try {


    //         console.log(dataLogin)
    //         const response = await axios.post('/user/login', { ...dataLogin })
    //         localStorage.setItem('token', response.data.data.token)
    //         localStorage.setItem('email', response.data.data.user)
    //         return { status: true }
    //     } catch (err) {
    //         return { status: false, msg: err.response.data.msg }
    //     }
    // }

    // const resetarSenha = async () => {
    //     try {


    //         console.log(dataLogin)
    //         const response = await axios.post('/user/login', { ...dataLogin })
    //         localStorage.setItem('token', response.data.data.token)
    //         localStorage.setItem('email', response.data.data.user)
    //         return { status: true }
    //     } catch (err) {
    //         return { status: false, msg: err.response.data.msg }
    //     }
    // }

    // const confirmarEmail = async () => {
    //     try {


    //         console.log(dataLogin)
    //         const response = await axios.post('/user/login', { ...dataLogin })
    //         localStorage.setItem('token', response.data.data.token)
    //         localStorage.setItem('email', response.data.data.user)
    //         return { status: true }
    //     } catch (err) {
    //         return { status: false, msg: err.response.data.msg }
    //     }
    // }

    //Perfil

    const loadPerfil = async () => {
        const req = {
            headers: {
                'authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }
        try {
            setLoading(true)
            const result = await axios.get('/profile/cliente', req)
            console.log(result.data.data)
            setLoading(false)
            setPerfil(result.data.data)
        } catch (e) {
            setLoading(false)
            toast.error(e.response.data.msg)
            console.log(e)
        }
    }

    const editPerfil = async (data, id) => {
        try {
            const req = {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }
            setLoading(true)
            await axios.put(`/profile/cliente`, data, req)
            setLoading(false)
            toast.success('Perfil atualizado')
            loadPerfil();
        } catch (e) {
            toast.error(e.response.data.msg)
            console.log(e)
        }
    }

    const trocarSenha = async (data) => {
        try {
            const req = {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }
            setLoading(true)
            await axios.put(`/user/changePassword`, data, req)
            setLoading(false)
            toast.success('Solicitação de troca de e-mail recebida com sucesso')
            loadPerfil();
        } catch (e) {
            setLoading(false)
            toast.error(e.response.data.msg)
            console.log(e)
        }

    }

    const trocarEmail = async (data) => {
        try {
            const req = {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }
            setLoading(true)
            await axios.post(`/user/changeemail`, data, req)
            setLoading(false)
            toast.success('Perfil atualizado')
            loadPerfil();
        } catch (e) {
            setLoading(false)
            toast.error(e.response.data.msg)
            console.log(e)
        }

    }

    const confirmarTrocarEmail = async (data) => {
        try {
            setLoading(true)
            await axios.put(`/user/changeemail/${data}`)
            setLoading(false)
            toast.success('Perfil atualizado')
            loadPerfil();
        } catch (e) {
            setLoading(false)
            toast.error(e.response.data.msg)
            console.log(e)
        }

    }

    const confirmarEmail = async (data) => {
        try {
            setLoading(true)
            await axios.put(`/user/authEmail/${data}`)
            setLoading(false)
            toast.success('Email autenticado com sucesso')
            loadPerfil();
        } catch (e) {
            setLoading(false)
            toast.error(e.response.data.msg)
            console.log(e)
        }

    }


    const forgotPassword = async () => {
        try {
            setLoading(true)
            await axios.post(`/user/forgotPassword`, { email: dataLogin.email })
            setLoading(false)
            toast.success('Solicitação de troca de senha recebida com sucesso')
        } catch (e) {
            console.log(e)
            setLoading(false)
            toast.error(e.response.data.msg)
        }
    }

    const forgotChangePassword = async (token) => {
        try {
            setLoading(true)
            await axios.post(`/user/forgotChangePass`, { token, senha: dataLogin.senha })
            setLoading(false)
            toast.success('Basta realizar login')
            return true;

        } catch (e) {
            console.log(e)
            setLoading(false)
            toast.error(e.response.data.msg)
            return false;
        }
    }








    return (
        <AuthContext.Provider value={{
            states, setters, loadDataHome, loadConfig, loadDataSobre, loadDataFaq, loadDataContato, loadDataNotFound, login, checkLogin, updateConfigSemArquivo, updateConfigComArquivo, postConfig,
            loadDataHomeSecao1, loadDataHomeSecao2, loadDataHomeSecao3, loadDataHomeSecao4, loadDataHomeSecao5, loadDataHomeSecao6, updateSecao1, updateSecao4, updateSecao6, updateSecao2, updateSecao3,
            updateSecao5, updateDataSobre, updateDataContato, updateDataNotFound, updateDataFaq, loadEmailsRecebidos, enviarEmail, enviarEmailCliente, getWithLimitBlog, createBlog, getBlogById, editlog,
            deleteBlog, searchEmailsRecebidos, loadPerfil, editPerfil, trocarSenha, forgotPassword, forgotChangePassword, trocarEmail, confirmarTrocarEmail, confirmarEmail
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider