


import { XMarkIcon } from '@heroicons/react/24/solid'
import { useState, useEffect } from 'react';
import Arquivos from '../Arquivos'
import Arquivo from '../Arquivo'


const Config = ({ data, upload }) => {


    const [objHome, setObjHome] = useState({})

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            setObjHome(data)

        }
    }, [data])


    const [files, setFiles] = useState([])
    const [fileSecao2, setFileSecao2] = useState([])
    const [isEdit, setIsEdit] = useState(false);

    const isValidHex = (hex) => /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hex);


    const placeholder = 'https://via.placeholder.com/150'; // URL de um placeholder de imagem

    const handleRemoveFile = (index) => {
        setFiles(files.filter((_, fileIndex) => fileIndex !== index));
    };


    const onSubmit = async (e) => {
        e.preventDefault();
        // 
        const updatedImages = {
            id: objHome.id,
            secao5: {
                titulo: { ...objHome.secao5?.titulo, titulo: objHome.secao5?.titulo?.titulo },
                texto: { ...objHome.secao5?.texto, description: objHome.secao5?.texto?.description },
                link: { ...objHome.secao5?.link, link: objHome.secao5?.link?.link },
            }
        };

        // console.log(updatedImages)
        const formData = new FormData();

        formData.append('secao5', JSON.stringify(updatedImages.secao5));

        upload(formData, objHome.id)


    }


    // console.log(data.secao3?.numbers[0]?.texto.description)


    return (
        <form className='flex w-full flex-col' onSubmit={onSubmit}>
            <div className="space-y-12">
                <div className="border-b border-gray-900/10 pb-12">

                    {/* INICIO SEÇÃO 5 */}

                    <div>
                        <div className='border rounded-[10px] mt-2 p-4'>
                            <h1 className="text-lg font-semibold leading-6 text-gray-900">Chamda para contato</h1>
                            {/* Link, subtitulo e texto */}
                            <div className="mt-6 border p-2 rounded-[10px]">
                                <label htmlFor="about" className="block text-sm font-medium text-gray-900">
                                    Texto do botão de contato:
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="link"
                                        maxLength={80}
                                        id="link"
                                        value={objHome.secao5?.link?.link}
                                        onChange={(e) => setObjHome({ ...objHome, secao5: { ...objHome.secao5, link: { ...objHome.secao5.link, link: e.target.value } } })}
                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>
                            <div className="mt-6 p-2 rounded-[10px] border">
                                <label htmlFor="about" className="block text-sm font-medium text-gray-900">
                                    Texto:
                                </label>
                                <div className="mt-1">
                                    <textarea
                                        type="text"
                                        name="texto"
                                        maxLength={200}
                                        id="texto"
                                        value={objHome.secao5?.texto?.description}
                                        onChange={(e) => setObjHome({ ...objHome, secao5: { ...objHome.secao5, texto: { ...objHome.secao5.texto, description: e.target.value } } })}
                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>
                            <div className="mt-6 p-2 rounded-[10px] border">
                                <label htmlFor="about" className="block text-sm font-medium text-gray-900">
                                    Título:
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        maxLength={200}
                                        name="subtitulo"
                                        id="subtitulo"
                                        value={objHome.secao5?.titulo?.titulo}
                                        onChange={(e) => setObjHome({ ...objHome, secao5: { ...objHome.secao5, titulo: { ...objHome.secao5.titulo, titulo: e.target.value } } })}
                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* FIM DA SEÇÃO 5 */}










                </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                    Cancel
                </button>
                <button
                    type="submit"
                    className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                    {
                        Object.keys(data).length > 0 ? 'Atualizar' : 'Salvar'
                    }
                </button>
            </div>
        </form >
    )


}



export default Config;
