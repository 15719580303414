import { useEffect, useContext, useState } from "react";
import Footer from "../../../components/Footer/Foooter";
import Header from "../../../components/Header/Header";
import { AuthContext } from "../../../context";
import './blogs.css'
import BlogGrid from "../../../components/Grid/BlogGrid";
// import Load from "../../../components/Load";
import ErrorSite from '../../../components/errorSite'



const Blogs = () => {



    const { states, setters, getWithLimitBlog } = useContext(AuthContext)
    const [config, setConfig] = useState({})

    const data = async () => {
        await getWithLimitBlog()
    }

    useEffect(() => {
        data()
        setConfig(JSON.parse(localStorage.getItem('config')) || '')
    }, [])

    const next = async () => {
        console.log('asdasd')
        if (states.limiteBuscaDataBlog) {
            return
        } else {
            setters.setOffsetDataBlog(states.offsetDataBlog + states.limitDataBlog)
        }


    };


    const previous = async () => {
        if (states.offsetDataBlog <= 0) {
            states.offsetDataBlog < 0 && setters.setOffsetDataBlog(0)
            return
        } else {
            if (states.limiteBuscaDataBlog) setters.setLimiteBuscaDataBlog(false)
            setters.setOffsetDataBlog(states.offsetDataBlog - states.limitDataBlog)
        }

    };

    useEffect(() => {
        if (states.dataBlog.length > 0) {
            data()
        }
        //Isso aqui atrapalha a paginacao
    }, [states.offsetDataBlog])


    return (
        <>
            <Header />
            <ErrorSite />
            <div className="relative mx-auto max-w-lg divide-y-2 divide-gray-200 lg:max-w-7xl p-6">
                <div>
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Blog</h2>
                    <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:items-center lg:gap-5">
                        <p className="text-xl text-gray-500">Aqui você encontra todas as postagens!</p>
                    </div>
                </div>
                {
                    states.dataBlog.length === 0 ? <>
                        <div>
                            <p className="text-xl text-gray-500">Nenhuma postagem encontrada!</p>
                        </div>
                    </> :
                        <BlogGrid states={states} setters={setters} dataLoad={states.dataBlog} config={config} next={next} previous={previous}
                        />
                }
            </div>
            <Footer />
        </>
    );

}


export default Blogs;